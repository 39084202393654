import React, { useEffect, useState } from 'react';
import { Comment } from 'types/comments.type';
import Comments from 'components/social/Comments';
import Drawer from 'components/ui-components-v2/Drawer';
import IconButton from 'components/ui-components-v2/IconButton';
import Badge from 'components/ui-components-v2/Badge';
import Icon from 'components/ui-components-v2/Icon';
import Typography from 'components/ui-components-v2/Typography';
import Translation from 'components/data/Translation';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CommentsHelpers } from 'helpers/comments.helpers';
import { entityConvert } from 'components/social/Comments/utilities';
import { useCommentsLink } from '../Comments/hooks';

import './styles/main.scss';

interface Props {
    entity: string;
    campaignId?: string | number;
    commentCategories: { [key: string]: string };
}

interface ComponentStoreCommentsProps {
    [entity: string]: Comment[];
}

const CommentsDrawer = ({ entity, campaignId, commentCategories }: Props) => {
    const [open, setOpen] = useState(false);
    const [commentsCategoryKey, setCommentsCategoryKey] = useState('all');
    const commentsLink = useCommentsLink();

    const { comments } = useComponentStore<ComponentStoreCommentsProps>('Comments', {
        fields: {
            comments: entity
        }
    });

    useEffect(() => CommentsHelpers.fetchComments(entityConvert(entity), campaignId, commentsLink), [entity, campaignId, commentsLink]);

    const badgeInvisible = (() => {
        if (open) return true;
        if (comments?.length) return false;
        return true;
    })();

    return (
        <React.Fragment>
            <IconButton size="small" onClick={() => setOpen(!open)}>
                <Badge color="primary" badgeContent={comments?.length} invisible={badgeInvisible}>
                    <Icon>{open ? 'close' : 'message'}</Icon>
                </Badge>
            </IconButton>
            <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)} className="comments-drawer">
                <div className="comments-drawer__container">
                    <div className="comments-drawer__header">
                        <Typography>{Translation.get('comments.comments', 'common')}</Typography>
                        <IconButton onClick={() => setOpen(false)} size="small">
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                    <div className="comments-drawer__content">
                        <Comments
                            onChangeCategory={(categoryKey) => setCommentsCategoryKey(categoryKey)}
                            categories={commentCategories}
                            categoryKey={commentsCategoryKey}
                            campaignId={campaignId}
                            entity={entity}
                        />
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default CommentsDrawer;
