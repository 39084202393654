const getCommentsLink = (id?: string, path?: string, path2?: string, existingLink?: string): string | undefined => {
    if (existingLink) return existingLink;

    let link = id;
    if (path) {
        link = `${link}/${path}`;
    }
    if (path2) {
        link = `${link}/${path2}`;
    }

    return link;
};

export { getCommentsLink };
