import React, { CSSProperties, RefObject, useState } from 'react';
import { Comment, CommentUser } from 'types/comments.type';
import CommentMarker, { CommentMarkerPopper } from 'components/social/CommentsMarker';
import { useCommentsContext } from 'components/social/context/comments.context';
import { CommentLines } from 'components/social/Comments/components/comment-content';

import '../styles/marker.scss';

interface Props {
    comment: Comment;
    overlay: RefObject<HTMLDivElement>;
    users: CommentUser[];
}

const CommentsShowMarkersOverlayMarker = ({ comment, overlay, users }: Props) => {
    const { visibleCommentMarkers } = useCommentsContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const style: CSSProperties = (() => {
        const style: CSSProperties = { visibility: visibleCommentMarkers.includes(comment.id) ? 'visible' : 'hidden' };
        if (comment.y !== null) style.top = comment.y + '%';
        if (comment.x !== null) style.left = comment.x + '%';
        return style;
    })();

    return (
        <React.Fragment>
            <CommentMarker
                onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                style={style}
                data-key={comment.id}
                user={comment.user}
            />
            <CommentMarkerPopper
                key={`marker-popper-${comment.id}`}
                open={visibleCommentMarkers.includes(comment.id) && Boolean(anchorEl)}
                overlay={overlay.current}
                anchorEl={anchorEl}>
                <div className="comment-markers-overlay-marker__popper">
                    <div className="comment-markers-overlay-marker__popper__inner">
                        <CommentLines comment={comment} users={users} dark={false} />
                    </div>
                </div>
            </CommentMarkerPopper>
        </React.Fragment>
    );
};

export default CommentsShowMarkersOverlayMarker;
