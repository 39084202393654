import React, { useRef, useState } from 'react';
import { useCommentsContext } from 'components/social/context/comments.context';
import Translation from 'components/data/Translation';
import CommentsAddMarkersOverlayInput from './input';

import '../styles/main.scss';

type Props = {
    milliseconds?: number;
};

/**
 * AddCommentOverlay is an absolute positioned element that fills its relative parent. The component makes it possible to click, place and save a comment + marker on a specific position.
 */
const CommentsAddMarkersOverlay = ({ milliseconds }: Props) => {
    const { setCommentsAddMarkersOverlayOpen } = useCommentsContext();
    const [coordinatesInPercentage, setCoordinatesInPercentage] = useState<{ x: string; y: string }>({ x: '', y: '' });
    const [open, setOpen] = useState(false);
    const overlay = useRef<HTMLDivElement>(null);

    const saveCoordinatesToState = (e: React.MouseEvent) => {
        const rect = overlay.current?.getBoundingClientRect();
        if (rect) {
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            // Calculate percentages
            const xPercentage = (x / rect.width) * 100;
            const yPercentage = (y / rect.height) * 100;
            setCoordinatesInPercentage({ x: xPercentage.toFixed(2) + '%', y: yPercentage.toFixed(2) + '%' });
            setOpen(true);
        }
    };

    const close = () => {
        setOpen(false);
        setCommentsAddMarkersOverlayOpen(false);
    };

    return (
        <div
            ref={overlay}
            className="comments-add-marker-overlay"
            onClick={(e) => {
                saveCoordinatesToState(e);
            }}>
            <div className="comments-add-marker-overlay__comment-placeholder">{Translation.get('comments.clickAnywhere', 'common')}</div>
            <CommentsAddMarkersOverlayInput
                overlay={overlay}
                coordinatesInPercentage={coordinatesInPercentage}
                milliseconds={milliseconds}
                open={open}
                onClose={close}
            />
        </div>
    );
};

export default CommentsAddMarkersOverlay;
