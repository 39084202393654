import EditorData from 'components/editor-data/EditorData';

const entityConvert = (entityInput: string): string => {
    let entity = (' ' + entityInput).slice(1);
    const flight = EditorData.get('flight');
    const language = EditorData.get('language');

    entity = entity.replace('[[flight]]', flight);
    entity = entity.replace('[[language]]', language);

    return entity;
};

export { entityConvert };
