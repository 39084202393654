import React from 'react';
import Popper from '@mui/material/Popper';

import '../styles/popper.scss';

interface Props {
    children: React.ReactNode;
    overlay: HTMLElement | null;
    anchorEl: HTMLElement | null;
    open: boolean;
}

const CommentMarkerPopper = ({ children, overlay, anchorEl, open }: Props) => {
    if (!anchorEl) return null;
    return (
        <Popper
            modifiers={[
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        rootBoundary: overlay,
                        padding: 8
                    }
                }
            ]}
            className="comment-marker-popper"
            placement="auto"
            container={overlay}
            open={open}
            anchorEl={anchorEl}>
            {children}
        </Popper>
    );
};

export default CommentMarkerPopper;
