import React, { useEffect, useRef, useState } from 'react';
import { Comment, CommentUser } from 'types/comments.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CommentsHelpers } from 'helpers/comments.helpers';
import { useCommentsContext } from 'components/social/context/comments.context';
import { getTaggableUsers } from 'components/social/Comments/utilities';
import EditorData from 'components/editor-data/EditorData';
import CommentsShowMarkersOverlayMarker from './marker';

import '../styles/main.scss';

interface ComponentStoreProps {
    [entity: string]: Comment[];
}
/**
 * AssetCommentOverlay is an absolute positioned overlay for assets that will display markers from comments with x and y coordinates.
 */
const CommentsShowMarkersOverlay = () => {
    const { entity, commentsLink } = useCommentsContext();
    const [users, setUsers] = useState<CommentUser[]>([]);
    const overlay = useRef<HTMLDivElement>(null);

    const { comments } = useComponentStore<ComponentStoreProps>('Comments', {
        fields: {
            comments: entity
        }
    });

    useEffect(() => {
        const campaignId = EditorData.getId();
        CommentsHelpers.fetchComments(entity, campaignId, commentsLink);
        fetchTaggableUsers();
    }, []);

    const fetchTaggableUsers = async () => {
        const users: CommentUser[] = await getTaggableUsers();
        setUsers(users);
    };

    return (
        <div ref={overlay} className="comment-markers-overlay">
            {comments &&
                comments.map((item) => {
                    return <CommentsShowMarkersOverlayMarker key={`marker-${item.id}`} comment={item} overlay={overlay} users={users} />;
                })}
        </div>
    );
};

export default CommentsShowMarkersOverlay;
