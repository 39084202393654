import React, { useRef } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import DateFormat from 'components/ui-components/DateFormat';
import User from 'components/data/User';
import { useCommentsContext } from 'components/social/context/comments.context';
import CommentMarker, { CommentMarkerPopper } from '../../CommentsMarker';
import CommentsInput from '../../CommentsInput';

import '../styles/input.scss';

interface Props {
    overlay: React.RefObject<HTMLDivElement>;
    coordinatesInPercentage: { x: string; y: string };
    milliseconds?: number;
    open: boolean;
    onClose: () => void;
}

const CommentsAddMarkersOverlayInput = ({ overlay, coordinatesInPercentage, milliseconds, open, onClose }: Props) => {
    const { entity, campaignId } = useCommentsContext();
    const markerRef = useRef<HTMLDivElement>(null);

    return (
        <React.Fragment>
            <CommentMarker
                ref={markerRef}
                user={User.get()}
                style={{ left: coordinatesInPercentage.x, top: coordinatesInPercentage.y, visibility: open ? 'visible' : 'hidden' }}
            />
            <CommentMarkerPopper open={open} overlay={overlay.current} anchorEl={markerRef.current}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="comments-add-marker-overlay-input">
                    {milliseconds !== undefined && (
                        <div className="comments-add-marker-overlay-input__time-stamp">
                            <Icon fontSize="inherit">timer</Icon>
                            <DateFormat date={new Date(milliseconds)} type="minSec" />
                        </div>
                    )}
                    <CommentsInput
                        type="edit"
                        dark={false}
                        entity={entity}
                        campaignId={campaignId}
                        onCancel={onClose}
                        x={coordinatesInPercentage.x}
                        y={coordinatesInPercentage.y}
                        placementInMilliseconds={milliseconds}
                        onSave={onClose}
                    />
                </div>
            </CommentMarkerPopper>
        </React.Fragment>
    );
};

export default CommentsAddMarkersOverlayInput;
