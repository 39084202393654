import React, { useRef, useEffect, useMemo } from 'react';
import { Comment } from 'types/comments.type';
import classNames from 'classnames';
import { useComponentSize } from 'hooks/useComponentSize';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CommentsHelpers } from 'helpers/comments.helpers';
import { useCommentsContext } from 'components/social/context/comments.context';
import CommentTimelineItem from './item';

import '../styles/main.scss';

type Props = {
    milliseconds: number;
    duration: number;
    handleProgressChange: (time: number) => void;
    setIsPlaying: (isPlaying: boolean) => void;
    className?: string;
};

interface ComponentStoreProps {
    [entity: string]: Comment[];
}

//Renders a bar with comments avatars
const CommentsTimeline: React.FC<Props> = ({ milliseconds, duration, handleProgressChange, setIsPlaying, className }) => {
    const { updateVisibleCommentMarkers, setVisibleCommentMarkers, entity, campaignId, commentsLink } = useCommentsContext();
    const commentBar = useRef<HTMLDivElement>(null);
    const { width: commentBarWidth } = useComponentSize(commentBar);

    const { comments } = useComponentStore<ComponentStoreProps>('Comments', {
        fields: {
            comments: entity
        }
    });

    useEffect(() => {
        CommentsHelpers.fetchComments(entity, campaignId, commentsLink);
        return () => {
            CommentsHelpers.cleanUpComments(entity);
        };
    }, []);

    const timelineComments = useMemo(() => {
        if (!comments) return [];
        return comments.filter((item) => item.time !== null);
    }, [comments]);

    const handleCommentTimelineItemClick = (id: string, goToTimeInSeconds: number) => {
        setVisibleCommentMarkers([id]);
        handleProgressChange(goToTimeInSeconds * 1000);
        setIsPlaying(false);
    };

    if (timelineComments.length === 0) return null;

    return (
        <div className={classNames('comments-timeline', className)}>
            <div className="comments-timeline__items" ref={commentBar}>
                {timelineComments.map((comment) => (
                    <CommentTimelineItem
                        assetCurrentTimeSeconds={milliseconds / 1000}
                        assetDurationMilliseconds={duration}
                        handleCommentTimelineItemClick={handleCommentTimelineItemClick}
                        commentBarWidth={commentBarWidth}
                        comment={comment}
                        updateVisibleCommentMarkers={updateVisibleCommentMarkers}
                        key={comment.id}
                    />
                ))}
            </div>
        </div>
    );
};

export default CommentsTimeline;
