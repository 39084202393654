import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { MentionsInput, Mention } from 'react-mentions';
import { Comment } from 'types/comments.type';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import ButtonMUI from 'components/ui-components-v2/Button';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import CommentReviewHelpers from 'components/data/CommentReviewHelpers';
import FileUpload from 'components/data/FileUpload';
import Avatar from 'components/ui-components-v2/Avatar';
import Translation from 'components/data/Translation';
import { CommentsHelpers } from 'helpers/comments.helpers';
import suggestionsStyle from './helpers/suggestion-style';
import UploadedFile from './components/uploaded-file';

import './styles/main.scss';

interface Props {
    /*Comments that we might reply on */
    comment?: Comment;
    campaignId?: string | number;
    /*Entity where the comment should be saved */
    entity: string;
    categories?: { [key: string]: string };
    categoryKey?: string;
    dark: boolean;
    parentId?: string;
    onSave: () => void;
    onCancel?: () => void;
    type: string;
    /*If a comment is placed over an asset, pass the x and y coordinates in percentages (e.g. '23.45%') */
    x?: string;
    y?: string;
    /*If a comment is placed on a certain timestamp, pass the currentTime in milliseconds*/
    placementInMilliseconds?: number;
}

interface ComponentStoreProps {
    [entity: string]: Comment[];
}

/**
 * Input
 * This component displays the input box for commenting. It also includes the taggable users.
 * Using the @ sign, you see a list of all the users that are in there.
 */
const CommentsInput = ({
    onCancel,
    dark,
    type,
    comment,
    categoryKey,
    categories,
    entity,
    parentId,
    campaignId,
    onSave,
    x,
    y,
    placementInMilliseconds
}: Props) => {
    const [text, setText] = useState(comment && type === 'edit' ? comment.text : '');
    const [status, setStatus] = useState(comment ? comment.status : '');
    const [fileUrl, setFileUrl] = useState<string | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();
    const [isUploading, setIsUploading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const { taggableUsers } = useComponentStore<ComponentStoreProps>('Comments', {
        fields: {
            taggableUsers: 'taggableUsers'
        }
    });

    useEffect(() => {
        CommentsHelpers.fetchTaggableUsers(entity);
        const input = document.querySelector('.comments__input--top textarea');
        const controls = document.querySelector('.comments__input--top .comments__input__controls');
        if (input && controls) {
            input.addEventListener('focus', () => {
                controls.classList.add('show');
            });
        }
        return () => {
            CommentsHelpers.cleanUpTaggableUsers();
        };
    }, []);

    /**
     * Handle text changes
     */
    const handleTextChange = (e) => {
        const text = e.target.value;
        setText(text);
    };

    /**
     * Handle save button click.
     *  Saves the post to the server, and also includes the tags.
     */
    const handleClickSendBtn = () => {
        // Category label
        let categoryLabel: string | undefined;
        const categoryKeyNew = categoryKey && categoryKey !== 'all' ? categoryKey : undefined;
        if (categories && categoryKeyNew && categories[categoryKeyNew] && categoryKeyNew !== 'all') {
            categoryLabel = categories[categoryKeyNew];
        }

        CommentsHelpers.saveComment({
            id: comment ? comment.id : 0,
            entity,
            text,
            campaignId,
            parentId,
            status,
            fileName,
            fileUrl,
            categoryKey,
            categoryLabel,
            x,
            y,
            time: placementInMilliseconds !== null ? placementInMilliseconds?.toString() : undefined,
            name: localStorage.getItem('commentsName') || undefined,
            email: localStorage.getItem('commentsEmail') || undefined
        });
        onSave();
        setText('');
        setStatus('');
        setFileUrl(undefined);
        setFileName(undefined);
    };

    const handleFileUploadStart = () => {
        setIsUploading(true);
    };

    const handleFileUploadComplete = (files) => {
        setIsUploading(false);
        setFileUrl(files[0].url);
        setFileName(files[0].fileName);
    };

    const handleRemoveFile = () => {
        setFileUrl(undefined);
        setFileName(undefined);
    };

    setTimeout(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, 0);

    return (
        <div
            className={classNames('comments__input', {
                'comments__input--small': type === 'small',
                'comments__input--dark': dark
            })}>
            <MentionsInput
                className="comments__mentions-input"
                id="mentions-input-element"
                inputRef={inputRef}
                value={text}
                onChange={handleTextChange}
                placeholder={
                    taggableUsers?.length
                        ? Translation.get('comments.inputPlaceholder', 'common')
                        : Translation.get('comments.externalPreview.inputPlaceholder', 'common')
                }
                style={suggestionsStyle}>
                <Mention
                    trigger="@"
                    data={taggableUsers}
                    markup="@__id__ "
                    displayTransform={(id) => CommentReviewHelpers.displayUser(id, taggableUsers)}
                    appendSpaceOnAdd
                    renderSuggestion={(entry) => {
                        return (
                            <div className="comments__input__suggestions__item">
                                <Avatar size="medium" src={entry.profilePicture}>
                                    {entry.display}
                                </Avatar>
                                {entry.display}
                            </div>
                        );
                    }}
                />
            </MentionsInput>
            <div className="comments__input__controls">
                <>
                    {fileName && fileUrl && <UploadedFile dark={dark} fileUrl={fileUrl} fileName={fileName} onRemove={handleRemoveFile} />}
                    <div>
                        {!fileName && (
                            <>
                                {!isUploading ? (
                                    <FileUpload
                                        dark={dark}
                                        fileType={['image', 'pdf']}
                                        onUploadingStart={handleFileUploadStart}
                                        onUploadComplete={handleFileUploadComplete}
                                        size="small"
                                        display="inline"
                                        showAttachFileIcon
                                    />
                                ) : (
                                    <CircularProgress size="1.2em" />
                                )}
                            </>
                        )}
                    </div>
                </>
                <div className="comments__input__controls__buttons">
                    {type !== 'comment' && (
                        <ButtonMUI size="small" onClick={onCancel}>
                            {Translation.get('actions.cancel', 'common')}
                        </ButtonMUI>
                    )}
                    <ButtonMUI size="small" disabled={isUploading || !text} onClick={handleClickSendBtn} color="primary" variant="contained">
                        {Translation.get('actions.send', 'common')}
                    </ButtonMUI>
                </div>
            </div>
        </div>
    );
};

export default CommentsInput;
