import { useParams } from 'react-router-dom';
import { getCommentsLink } from '../utilities';

/**
 * Custom hook to get and process URL parameters
 */
export const useCommentsLink = (existingLink?: string): string | undefined => {
    const { id, path, path2 } = useParams();
    return getCommentsLink(id, path, path2, existingLink);
};
