import { useInCampaign } from 'hooks/useInCampaign';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import EditorData from 'components/editor-data/EditorData';

type CommentsContextType = {
    entity: string;
    campaignId?: string;
    commentsLink?: string;
    commentsAddMarkersOverlayOpen: boolean;
    setCommentsAddMarkersOverlayOpen: (showCommentsAddMarkersOverlay: boolean) => void;
    visibleCommentMarkers: string[];
    setVisibleCommentMarkers: (visibleCommentMarkers: string[]) => void;
    updateVisibleCommentMarkers: (action: 'add' | 'remove', commentId: string) => void;
};

interface Props {
    children: ReactNode;
    entity: string;
    commentsLink?: string;
}

const CommentsContext = createContext<CommentsContextType>({
    entity: '',
    commentsLink: undefined,
    commentsAddMarkersOverlayOpen: true,
    setCommentsAddMarkersOverlayOpen: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    visibleCommentMarkers: [],
    setVisibleCommentMarkers: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateVisibleCommentMarkers: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
});
CommentsContext.displayName = 'CommentsContext';

const useCommentsContext = (): CommentsContextType => {
    const context = useContext(CommentsContext);

    if (!context) {
        throw new Error('useCommentsContext must be used within a CommentsProvider');
    }
    return context;
};

const CommentsProvider = ({ children, entity, commentsLink }: Props) => {
    const [commentsAddMarkersOverlayOpen, setCommentsAddMarkersOverlayOpen] = useState<boolean>(false);
    const [visibleCommentMarkers, setVisibleCommentMarkers] = useState<string[]>([]);
    const inCampaign = useInCampaign();

    // We want to check if we're actually in a campaign now, because the campaign id cleared after leaving a campaign.
    const campaignId = inCampaign ? EditorData.getId() : undefined;

    // Add or remove comment ID's to the visibleCommentMarkers array
    const updateVisibleCommentMarkers = (action: 'add' | 'remove', commentId: string) => {
        if (action === 'add') {
            setVisibleCommentMarkers([...visibleCommentMarkers, commentId]);
        }
        if (action === 'remove') {
            setVisibleCommentMarkers((prev) => {
                return prev.filter((id) => id !== commentId);
            });
        }
    };

    const contextValue = {
        entity,
        campaignId,
        commentsLink,
        commentsAddMarkersOverlayOpen,
        setCommentsAddMarkersOverlayOpen,
        visibleCommentMarkers,
        setVisibleCommentMarkers,
        updateVisibleCommentMarkers
    };

    return <CommentsContext.Provider value={contextValue}>{children}</CommentsContext.Provider>;
};

export default CommentsProvider;
export { useCommentsContext, CommentsContext, CommentsProvider };
