import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { MentionsInput, Mention } from 'react-mentions';
import { Comment, CommentUser } from 'types/comments.type';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import ButtonMUI from 'components/ui-components-v2/Button';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import CommentReviewHelpers from 'components/data/CommentReviewHelpers';
import FileUpload from 'components/data/FileUpload';
import Avatar from 'components/ui-components-v2/Avatar';
import Translation from 'components/data/Translation';
import { CommentsHelpers } from 'helpers/comments.helpers';
import User from 'components/data/User';
import suggestionsStyle from '../helpers/suggestion-style';
import UploadedFile from './uploaded-file';
import AnonymousDialog from './anonymous-dialog';

import '../styles/input.scss';

interface Props {
    comment?: Comment;
    campaignId?: string | number;
    entity: string;
    users: CommentUser[];
    categories?: { [key: string]: string };
    categoryKey?: string;
    dark: boolean;
    parentId?: string;
    onSave: () => void | undefined;
    onCancel?: () => void;
    type: string;
    inputRef?: React.Ref<HTMLInputElement | null>;
}

/**
 * Input
 * This component displays the input box for commenting. It also includes the taggable users.
 * Using the @ sign, you see a list of all the users that are in there.
 */
const Input = ({ onCancel, dark, type, comment, categoryKey, categories, entity, parentId, campaignId, onSave, users, inputRef }: Props) => {
    const [text, setText] = useState(comment && type === 'edit' ? comment.text : '');
    const [status, setStatus] = useState(comment ? comment.status : '');
    const [fileUrl, setFileUrl] = useState<string | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();
    const [isUploading, setIsUploading] = useState(false);
    const [anonymousDialogOpen, setAnonymousDialogOpen] = useState<boolean>();

    /**
     * Handle text changes
     */
    const handleTextChange = (e) => {
        const text = e.target.value;
        setText(text);
    };

    /**
     * Handle save button click.
     *  Saves the post to the server, and also includes the tags.
     */
    const handleClickSendBtn = () => {
        // Check anonymous
        const name = localStorage.getItem('commentsName') || undefined;
        const email = localStorage.getItem('commentsEmail') || undefined;

        if (!User.get('id') && (!name || !email)) {
            setAnonymousDialogOpen(true);
            return;
        }

        if (text === '' && status === '') {
            //A general error message as the button is already disabled.
            SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
        }

        // Category label
        let categoryLabel: string | undefined;
        const categoryKeyNew = categoryKey && categoryKey !== 'all' ? categoryKey : undefined;
        if (categories && categoryKeyNew && categories[categoryKeyNew] && categoryKeyNew !== 'all') {
            categoryLabel = categories[categoryKeyNew];
        }

        // Save comment
        if (text !== '' || status !== '') {
            CommentsHelpers.saveComment({
                id: comment ? comment.id : 0,
                entity,
                text,
                status,
                fileName,
                fileUrl,
                parentId,
                campaignId,
                categoryKey: categoryKeyNew,
                categoryLabel,
                name,
                email
            });
            if (onSave) {
                onSave();
            }
            setText('');
            setStatus('');
            setFileUrl(undefined);
            setFileName(undefined);
        }
    };

    /**
     *  Handle anonymous dialog
     * @param formData
     */
    const handleAnonymousDialog = (formData) => {
        if (formData) {
            localStorage.setItem('commentsName', formData.name);
            localStorage.setItem('commentsEmail', formData.email);
            handleClickSendBtn();
        }
        setAnonymousDialogOpen(false);
    };

    const handleFileUploadStart = () => {
        setIsUploading(true);
    };

    const handleFileUploadComplete = (files) => {
        setIsUploading(false);
        setFileUrl(files[0].url);
        setFileName(files[0].fileName);
    };

    const handleRemoveFile = () => {
        setFileUrl(undefined);
        setFileName(undefined);
    };

    useEffect(() => {
        const input = document.querySelector('.comments__input--top textarea');
        const controls = document.querySelector('.comments__input--top .comments__input__controls');
        if (input && controls) {
            input.addEventListener('focus', () => {
                controls.classList.add('show');
            });
        }
    }, []);

    return (
        <div
            className={classNames('comments__input', {
                'comments__input--small': type === 'small',
                'comments__input--dark': dark
            })}>
            <MentionsInput
                className="comments__mentions-input"
                id="mentions-input-element"
                inputRef={inputRef}
                value={text}
                onChange={handleTextChange}
                placeholder={
                    users?.length
                        ? Translation.get('comments.inputPlaceholder', 'common')
                        : Translation.get('comments.externalPreview.inputPlaceholder', 'common')
                }
                style={suggestionsStyle}>
                <Mention
                    trigger="@"
                    data={users}
                    markup="@__id__ "
                    displayTransform={(id) => CommentReviewHelpers.displayUser(id, users)}
                    appendSpaceOnAdd
                    renderSuggestion={(entry) => {
                        return (
                            <div className="comments__input__suggestions__item">
                                <Avatar size="medium" src={entry.profilePicture}>
                                    {entry.display}
                                </Avatar>
                                {entry.display}
                            </div>
                        );
                    }}
                />
            </MentionsInput>
            <div className="comments__input__controls">
                {User.get('id') > 0 && (
                    <>
                        {fileName && fileUrl && <UploadedFile dark={dark} fileUrl={fileUrl} fileName={fileName} onRemove={handleRemoveFile} />}
                        <div>
                            {!fileName && (
                                <>
                                    {!isUploading ? (
                                        <FileUpload
                                            dark={dark}
                                            fileType={['image', 'pdf']}
                                            onUploadingStart={handleFileUploadStart}
                                            onUploadComplete={handleFileUploadComplete}
                                            size="small"
                                            display="inline"
                                            showAttachFileIcon
                                        />
                                    ) : (
                                        <CircularProgress size="1.2em" />
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
                <div className="comments__input__controls__buttons">
                    {type !== 'comment' && (
                        <ButtonMUI size="small" onClick={onCancel}>
                            {Translation.get('actions.cancel', 'common')}
                        </ButtonMUI>
                    )}
                    <ButtonMUI size="small" disabled={isUploading || !text} onClick={handleClickSendBtn} color="primary" variant="contained">
                        {Translation.get('actions.send', 'common')}
                    </ButtonMUI>
                </div>
            </div>

            {anonymousDialogOpen && <AnonymousDialog onClose={handleAnonymousDialog} />}
        </div>
    );
};

export default Input;
