import React, { useRef, useEffect, useState, CSSProperties } from 'react';
import { Comment, CommentUser } from 'types/comments.type';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Avatar from 'components/ui-components-v2/Avatar';
import DateFormat from 'components/ui-components/DateFormat';
import IconButton from 'components/ui-components-v2/IconButton';
import { getTaggableUsers } from 'components/social/Comments/utilities';
import { CommentLines } from 'components/social/Comments/components/comment-content';
import Typography from 'components/ui-components-v2/Typography';

import '../styles/item.scss';

type Props = {
    assetCurrentTimeSeconds: number;
    assetDurationMilliseconds: number;
    commentBarWidth: number;
    comment: Comment;
    handleCommentTimelineItemClick: (id: string, goToTimeInSeconds: number) => void;
    updateVisibleCommentMarkers: (action: 'add' | 'remove', commentId: string) => void;
};

const AUTO_OPEN_TIME_MARGIN = 250;

const CommentTimelineItem = ({
    assetCurrentTimeSeconds,
    commentBarWidth,
    comment,
    assetDurationMilliseconds,
    handleCommentTimelineItemClick,
    updateVisibleCommentMarkers
}: Props) => {
    const commentTimelineItem = useRef<HTMLDivElement>(null);
    const [users, setUsers] = useState<CommentUser[]>([]);
    const [open, setOpen] = useState(false);
    const [autoOpen, setAutoOpen] = useState(false);
    const [style, setStyle] = useState<CSSProperties>({ left: undefined, transform: undefined });

    const autoOpenTime = Number(comment.time) - AUTO_OPEN_TIME_MARGIN;
    const autoCloseTime = Number(comment.time) + AUTO_OPEN_TIME_MARGIN;

    useEffect(() => {
        fetchTaggableUsers();
    }, []);

    const fetchTaggableUsers = async () => {
        const users: CommentUser[] = await getTaggableUsers();
        setUsers(users);
    };

    useEffect(() => {
        calcCommentPosition(commentBarWidth, assetDurationMilliseconds);
    }, [commentBarWidth, assetDurationMilliseconds]);

    useEffect(() => {
        if (!autoOpen) {
            shouldCommentAutoOpen();
        } else {
            shouldCommentAutoClose();
        }
    }, [assetCurrentTimeSeconds]);

    const shouldCommentAutoOpen = () => {
        const currentTimeInMilliseconds = assetCurrentTimeSeconds * 1000;
        if (currentTimeInMilliseconds >= autoOpenTime && currentTimeInMilliseconds <= autoCloseTime) {
            setAutoOpen(true);
            updateVisibleCommentMarkers('add', comment.id);
        } else {
            setAutoOpen(false);
            updateVisibleCommentMarkers('remove', comment.id);
        }
    };

    const shouldCommentAutoClose = () => {
        const currentTimeInMilliseconds = assetCurrentTimeSeconds * 1000;
        if (currentTimeInMilliseconds < autoOpenTime || currentTimeInMilliseconds > autoCloseTime) {
            setAutoOpen(false);
            updateVisibleCommentMarkers('remove', comment.id);
        }
    };

    const onMouseLeave = () => {
        setAutoOpen(false);
        setOpen(false);
    };

    const onMouseEnter = () => {
        setAutoOpen(true);
        setOpen(true);
    };

    const calcCommentPosition = (commentBarWidth: number, assetDurationMilliseconds: number) => {
        if (!commentBarWidth || !assetDurationMilliseconds || !commentTimelineItem.current?.style) return;
        const widthDividedByMilliSeconds = commentBarWidth / assetDurationMilliseconds;
        const commentPosition = Math.round(widthDividedByMilliSeconds * Number(comment.time));
        const left = commentPosition - commentTimelineItem.current.clientWidth / 2 + 'px';
        setStyle({ left });
    };

    const tooltip = (
        <div className="comments-timeline-item__tooltip">
            <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                {comment.user.name}
                {' @ '}
                <DateFormat date={new Date(Number(comment.time))} type="minSec" />
            </Typography>
            <CommentLines comment={comment} users={users} dark={true} />
        </div>
    );

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="comments-timeline-item" style={style} ref={commentTimelineItem}>
            <Tooltip open={autoOpen || open} title={tooltip} arrow placement="top">
                <IconButton
                    onClick={() => {
                        handleCommentTimelineItemClick(comment.id, Number(comment.time) / 1000);
                    }}>
                    <Avatar size="small" src={comment.user.profilePicture}>
                        {comment.user.name}
                    </Avatar>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default CommentTimelineItem;
