import { CommentUser } from 'types/comments.type';
import { UserProfile } from 'components/data/UserProfileHelpers/types/user-profile.type';

const convertUserToCommentUser = (user: UserProfile): CommentUser => {
    return {
        display: user.name,
        id: user.username,
        profilePicture: user.profilePicture,
        brands: user.brands,
        departments: user.departments,
        markets: user.markets
    };
};

export { convertUserToCommentUser };
