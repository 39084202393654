import { CommentUser } from 'types/comments.type';
import { UserProfileHelpers } from 'components/data/UserProfileHelpers';
import { convertUserToCommentUser } from './convertUserToCommentUser';

const getTaggableUsers = async (): Promise<CommentUser[]> => {
    try {
        const profiles = await UserProfileHelpers.getUserProfiles();
        return profiles.map((profile) => convertUserToCommentUser(profile));
    } catch (error) {
        return [];
    }
};

export { getTaggableUsers };
