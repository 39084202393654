import React, { CSSProperties, forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Comment } from 'types/comments.type';
import Avatar from 'components/ui-components-v2/Avatar';
import { UserProfile } from 'components/data/UserProfileHelpers/types/user-profile.type';

import '../styles/main.scss';

type Props = {
    user: UserProfile | Comment['user'];
    style?: CSSProperties;
    className?: string;
    onMouseEnter?: MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: MouseEventHandler<HTMLDivElement>;
};

const CommentMarker = forwardRef<HTMLDivElement, Props>(({ user, style, onMouseEnter, onMouseLeave, className }, ref) => {
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style} ref={ref} className={classNames('comment-marker', className)}>
            <Avatar size="large" src={user.profilePicture}>
                {user.name}
            </Avatar>
        </div>
    );
});

CommentMarker.displayName = 'CommentMarker';

export default CommentMarker;
